<template>
  <v-card class="pb-0 px-0" >
    <v-card-title class="justify-center">
      <span class="headline">{{ $t(test.area) }}</span>
    </v-card-title>
    <v-card-text>
      <v-row >
        <v-col
          v-show="test.questions[position].picture"
        ><v-img
            :src="`${apiUrl}/images/test/${test.questions[position].picture}`"
            contain
          ></v-img>
        </v-col>
        
        <v-col cols="12" md="12" lg="12" xlg="12" class="text-center">
          <span class="headline"
            >{{ test.questions[position].question }}
            <!-- ({{
              test.questions[position].percentage
            }}%) -->
            </span
          >
        </v-col>

        <v-col cols="12" md="12" lg="12" xlg="12" class="text-center pa-0">
          <v-row
            v-if="test.questions[position].alternatives[0].answer" 
          >
            <v-col
              cols="12"
              md="12"
              class="pa-1"
              v-for="(alternative, index) in questions[position].alternatives"
              :key="index"
            >
            <v-card
                class=""
                max-width="800"
                flat
                fluid
                style="border: 1px solid #f7a400;  border-radius: 5px;"
                @click="selectAnswer(index)"
                :style="{ 'background': alternative.response ? '#f7a400':'' , 'color': alternative.response ? '#fff':'#fff'}"
               
              >
                <v-card-text style="padding-top: -20px">
                  <div
                    class="flex-grow-1 text-center"
                    :style="{ color: alternative.response ? '#fff' : '#000' }"
                  >
                    {{ alternative.answer }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
             <v-col
              cols="12"
              md="12"
              class="pa-1"
              v-else
             
            >
          <v-textarea
           
            v-model="questions[position].answer"
            rows="3"
            outlined
            :label="$t('answer')"
           class="pt-5"
          ></v-textarea>
             </v-col>
        </v-col>
        <v-row class="pt-2 justify-center px-3" no-gutters>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-col cols="12" class="px-6">
          <v-progress-linear
            v-model="time"
            :buffer-value="bufferValue"
          ></v-progress-linear>
        </v-col>
        <v-col cols="12" class="px-6">
          <v-btn
            v-if="position < questions.length - 1"
            block
            color="primary"
            tile
            @click="nextQuestion()"
            >{{$t('next')}} {{ position + 1 }}/{{ questions.length }}</v-btn
          >
          <v-btn
            v-else
            block
            color="primary"
            tile
            @click="(dialog = false), nextQuestion()"
            >{{$t('finish')}} {{ position + 1 }}/{{ questions.length }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "Quiz",
  props: {
    test: Object,
    start: Boolean,
  },
  data: () => ({
    apiUrl: API_URL,
    bufferValue: 100,
    interval: 0,
    position: 0,
    questions: [
      {
        answer: "",
        question: "",
        alternatives: [
          {
            answer: "",
            value: false,
            response: false,
          },
        ],
        totalCorrect: 0,
        status: false,
        picture: undefined,
      },
    ],
    resultTest: undefined,
    time: 0,
    clicked: false,
  }),
  watch: {
    time(val) {
      if (val < 100) return;

      if (this.position >= this.test.questions.length - 1) {
        clearInterval(this.interval);
      }
      this.nextQuestion();
    },
    start(val) {
      if (val) {
        this.startBuffer();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.questions = this.test.questions.map((q) => ({
      ...q,
      alternatives: q.alternatives.map((a) => ({ ...a, response: false })),
      answer: ''
    }));
    this.startBuffer();
  },
  methods: {
    getDuraction(test) {
      const t = test.duraction;

      let ms =
        Number(t.split(":")[0]) * 60 * 60 * 1000 +
        Number(t.split(":")[1]) * 60 * 1000;

      return ms / test.questions.length / 100;
    },
    nextQuestion() {
      if (this.position < this.test.questions.length - 1) {
        this.position += 1;
      } else {
        clearInterval(this.interval);
        this.calculateFinalResult();
        this.$emit("finish", {
          testId: this.test.id,
          questions: this.questions,
          result: this.resultTest,
        });
      }
      this.time = 0;
    },
    selectAnswer(pos) {
      let value = this.questions[this.position].alternatives[pos].response;
      if (this.questions[this.position].totalCorrect == 1) {
        for (
          let index = 0;
          index < this.questions[this.position].alternatives.length;
          index++
        ) {
          this.questions[this.position].alternatives[index].response = false;
        }
      }
      this.questions[this.position].alternatives[pos].response = value
        ? false
        : true;
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.time += 1;
      }, this.getDuraction(this.test));
    },
    calculateFinalResult() {
      let result = 0;
      this.questions.forEach((q) => {
        let total = 0;
        for (let index = 0; index < q.alternatives.length; index++) {
          if (q.alternatives[index].value && q.alternatives[index].response) {
            total += 1;
          }
        }
        if (total == q.totalCorrect) {
          result = result + q.percentage;
        }
      });

      this.resultTest = result;
    },
  },
};
</script>
