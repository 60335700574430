import gql from 'graphql-tag'

export const GET_TESTS_CANDIDATE_QUERY = gql`
  query GetTestsCandidate($filterTest: FilterTest) {
    tests: getTestsCandidate(filterTest: $filterTest) {
      tests {
        id
        date
        duraction
        entity {
          id
          name
        }
        questions {
          question
          objective
          implication
          alternatives {
            answer
            value
          }
          totalCorrect
          percentage
          picture
        }
        status
        time
        vacancy {
          id
          title
        }
      }
    }
  }
`