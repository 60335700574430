<template>
  <v-dialog v-model="dialog" persistent :max-width="status === 'finish'? '350':'750'">
    <info-test
      :test="test"
      @close="closeDialog()"
      @start="setStart()"
      v-if="status === 'stop'"
    />
    <quiz
      :start="start"
      :test="test"
      @finish="finish"
      v-else-if="status === 'start'"
    />
    <v-card
      v-else-if="status === 'finish'"
    >
       <v-system-bar
        window
        color="white"
      >
        <v-spacer></v-spacer>
        <v-btn small icon color="primary" @click="closeDialog()">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
     <v-card-text class="black--text d-flex flex-column text-center justify-center pt-1 pb-8">
        <div class="mb-2">
          <v-icon large color="success">mdi-check-circle-outline</v-icon>
        </div>
        <div>
          <span v-html="$t('solveTest')"> </span>
        </div>
        <div class="pt-2">
          <v-btn
            small
            color="primary"
            @click="closeDialog()"
          >
            <span class="white--text">{{
            $t("close")
          }}</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- <v-card-title class="subtitle-1 text-center justify-center py-5">
        <span v-html="$t('solveTest')"> </span>
      </v-card-title>
      <v-row justify="center">
        <v-col cols="12" md="6" class="pa-5 pt-0">
          <v-btn block color="primary" large @click="closeDialog()">{{
            $t("close")
          }}</v-btn>
        </v-col>
      </v-row> -->
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { SOLVE_TEST_MUTATION } from "./../graphql/Mutation.service";
import { mapActions } from "vuex";
import { formatError } from "@/utils";
import InfoTest from "./InfoTest.vue";
import Quiz from "./Quiz.vue";
import ErrorDialog from "./../../../components/ErrorDialog";
import { ADD_DIALOG } from "@/mixins/dialog";
export default {
  components: { ErrorDialog, InfoTest, Quiz },
  name: "SolveTestDialog",
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    test: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    status: "stop",
    start: false,
  }),
  created() {
    this.status = "stop";
    this.start = false;
  },
  methods: {
    ...mapActions({
      updateTestState: "test/updateTest",
    }),
    closeErrorDialog(value) {
      this.showError = value;
      this.status = "stop";
      this.start = false;
      this.$emit("close");
    },
    async finish(params) {
      try {
        this.status = "finish";
        this.start = false;
        const { data } = await this.$apollo.mutate({
          mutation: SOLVE_TEST_MUTATION,
          variables: { ...params },
        });
        this.updateTestState(data.solveTest);
        
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    closeDialog() {
      this.status = "stop";
      this.start = false;
      this.dialog = false;
      this.$emit("close");
    },
    setStart() {
      this.status = "start";
      this.start = true;
    },
  },
};
</script>